import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import Logo from './icon.svg';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './LoginComponent.css'; // Your custom styles

const LoginComponent = () => {
  const [deltaLogin, setDeltaLogin] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(deltaLogin)) {
        return toast.error('Please enter a valid email address for Delta Login.');
      }
  
    try {
      // Make an HTTP request using Axios to your login endpoint
      // Replace the URL and data with your actual backend API endpoint and data
      const response = await axios.post('YOUR_LOGIN_API_ENDPOINT', {
        deltaLogin,
        password,
        // Other login data...
      });

      // Handle successful login
      toast.success('Login successful!');
      console.log('Login successful:', response.data);

      // Clear the form after successful login
      setDeltaLogin('');
      setPassword('');
    } catch (error) {
      // Handle login error
      toast.error('Login failed. Please check your credentials and try again.');
      console.error('Login error:', error);
    }
  };

  return (
    <div className="login">
      <div className="container mt-5">
        <div className="row">
          <div className="card">
            <div className="card-header text-center">
              <div className="auth-text">
                <h3 className="mb-0">LOGIN</h3>
              </div>
              <div className="auth-logo">
                <img src={Logo} alt="Logo" className="auth-logo-image" />
              </div>
            </div>
            <div className="card-body">
              <p className="paragraph">Delta Account Login</p>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Delta Login or PPR:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={deltaLogin}
                    onChange={(e) => setDeltaLogin(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password:</label>
                  <div className="password-field">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="toggle-password btn btn-light"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                    </button>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  LOGIN
                </button>
              </form>
              <p>
                Don't have an account?{' '}
                {/* Use Link to create a link to the registration page */}
                <Link to="/register">Sign Up</Link>
              </p>
              <p>UNAUTHORIZED USE IS PROHIBITED.</p>
              <p className="small-text">
                Delta systems contain information and transactions for Delta business
                and must be protected from unauthorized access.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LoginComponent;
