import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import Logo from './icon.svg';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './RegisterComponent.css'; // Your custom styles

const RegisterComponent = () => {
  const [deltaLogin, setDeltaLogin] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(deltaLogin)) {
        return toast.error('Please enter a valid email address for Delta Login.');
      }
  
    // Simple password validation using regex
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    if (!passwordRegex.test(password)) {
      return toast.error(
        'Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.'
      );
    }

    if (password !== confirmPassword) {
      return toast.error('Passwords do not match.');
    }

    try {
      // Make an HTTP request using Axios to your registration endpoint
      // Replace the URL and data with your actual backend API endpoint and data
      const response = await axios.post('YOUR_REGISTRATION_API_ENDPOINT', {
        deltaLogin,
        password,
        // Other registration data...
      });

      // Handle successful registration
      toast.success('Registration successful!');
      console.log('Registration successful:', response.data);

      // Clear the form after successful registration
      setDeltaLogin('');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      // Handle registration error
      toast.error('Registration failed. Please try again.');
      console.error('Registration error:', error);
    }
  };

  return (
    <div className="register">
      <div className="container mt-5">
        <div className="row">
            <div className="card">
              <div className="card-header text-center">
              <div className='auth-text'>
                <h3 className="mb-0">SIGN UP</h3>
              </div>
                <div className="auth-logo">
                  <img src={Logo} alt="Logo" className="auth-logo-image"/>
                </div>
              </div>
              <div className="card-body">
                <p className="paragraph">Authorized Delta Account Information</p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">E-mail:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={deltaLogin}
                      onChange={(e) => setDeltaLogin(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Password:</label>
                    <div className="password-field">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        className="toggle-password btn btn-light"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                      </button>
                    </div>
                  </div>
                  <div className="mb-3 position-relative">
                    <label className="form-label">Confirm Password:</label>
                    <div className="password-field">
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        className="toggle-password btn btn-light"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                      </button>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    SIGN UP
                  </button>
                </form>
                <p >
                    or Login instead?{' '}
                    {/* Use Link to create a link to the login page */}
                    <Link to="/login">Login</Link>
                </p>                <p>UNAUTHORIZED USE IS PROHIBITED.</p>
                <p className="small-text">
                  Delta systems contain information and transactions for Delta business
                  and must be protected from unauthorized access.
                </p>
              </div>
            </div>
          </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RegisterComponent;
