// Reviews.jsx

import React from 'react';
import './Reviews.css';
import { Card, Col, Row } from 'react-bootstrap';
import nathanleadford from './Nathan-Leadford.webp';
import lindsey from './careers-lindsey-jalil-578.png';
import carmela from './careers-carmela-carter-two-700.webp';
const Reviews = () => {
  return (
    <div className="reviews">
      <Row>
        <Col md={6}>
          <Card className="review-card">
            <Card.Img
              variant="top"
              src={carmela}
              alt="Carmela Carter"
              className="img-fluid"
            />
          </Card>
        </Col>
        <Col md={6}>
          <Card className="review-card">
            <Card.Body>
              <Card.Title>CARMELA CARTER, SENIOR ANALYST, TECHNICAL PROCEDURES, ARMY SERGEANT VETERAN</Card.Title>
              <Card.Text>
                "Almost immediately after starting at Delta I knew this was the place for me because the core values almost mirror those in the Army – loyalty, honor, respect, selfless service, and integrity. Delta is an amazing place to work for Veterans. There are so many opportunities in the company where the skills learned in the military can be used."
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Card className="review-card">
            <Card.Img
              variant="top"
              src={nathanleadford}
              alt="Nathan Ledford"
              className="img-fluid"
            />
          </Card>
        </Col>
        <Col md={6}>
          <Card className="review-card">
            <Card.Body>
              <Card.Title>NATHAN LEDFORD, FLIGHT ATTENDANT</Card.Title>
              <Card.Text>
                “As a Delta Air Lines Flight Attendant, I am proud to represent Delta's strong family culture and how it drives us to be 'different' than our competition to win in the marketplace. Our leaders are approachable, value employee feedback and empower us to be the best at what we do. Teamwork fuels our passion to succeed, innovate and Keep Climbing!”
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Card className="review-card">
            <Card.Img
              variant="top"
              src={lindsey}
              alt="Lindsey Jalil"
              className="img-fluid"
            />
          </Card>
        </Col>
        <Col md={6}>
          <Card className="review-card">
            <Card.Body>
              <Card.Title>LINDSEY JALIL, MANAGING DIRECTOR OF CORPORATE PLANNING</Card.Title>
              <Card.Text>
                “I’ve led the employee testing program during COVID-19 and am really proud of the work we’re doing. Delta has always led the way with thoughtful and innovative solutions, and it’s reassuring to see that we were able to adapt quickly to protect employees and customers. The willingness of our people to do whatever it takes to keep our employees, our customers and loved ones safe is incredible.”
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Add more rows and cards as needed */}
    </div>
  );
};

export default Reviews;
