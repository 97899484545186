import React, { useState, useEffect } from 'react';
import './Homepage.css'; // Import your main styles
import {  useNavigate } from 'react-router-dom';
import deltaplanes from './Homepage-Images/DeltaPlanes.jpg';
import deltaworker from './Homepage-Images/deltaworker.jpeg';
import deltaplane from './Homepage-Images/DeltaPlane.png';
import SearchJobs from './SearchJobs';

const Homepage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [deltaplanes, deltaworker, deltaplane];
  const Navigate = useNavigate();

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(slideInterval);
  }, [images.length]);

  // Generate class name based on image file name
  const imageClassName = (imageName) => {
    return imageName.toLowerCase().replace(/\s/g, '-');
  };

  const handleApplyClick = () => {
    // Navigate to /apply-job
    Navigate('/apply-jobs');
  };
  return (
    <div>
    <div className='mobile-view'>
      {/* Bootstrap Jumbotron */}
      <div className="jumbotron">
        <img src={deltaworker} alt="Delta Worker" className="img-fluid" />
        <h1 className="display-4">Welcome to Delta Online Airlines Jobs</h1>
        <p className="lead">
          Join us, shape the future of air travel with exceptional service.
        </p>
        <hr className="my-4" />
        <p>Discover how you can contribute to the success of Delta Online Airlines.</p>
        <button className="btn btn-primary apply-button" onClick={handleApplyClick}>
          Job Application
        </button>
      </div>
    </div>
      {/* Your existing homepage content */}
      <div className="homepage-container">
        <div className={`background-slider ${imageClassName(images[currentIndex])}`} style={{ backgroundImage: `url(${images[currentIndex]})`, backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
          <div className="content">
            <h1 className="homepage-title">Delta Online Jobs</h1>
            <button className="apply-button" onClick={handleApplyClick}>
              Job Application Ongoing
            </button>
              </div>
        </div>
      </div>
      <SearchJobs/>
    </div>
  );
};

export default Homepage;
