// Install react-transition-group
// npm install react-transition-group

// SearchJobs.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Reviews from './Reviews/Reviews';
import { Card } from 'react-bootstrap'; // Import Bootstrap components
import './SearchJobs.css';
import employeesvolunteer from './Success/images/employeesvolunteer.png';
import atlantaprideparade from './Success/images/atlanta-pride-parade.jpg';
import Benefits from './Benefits/Benefits';
import { IoIosArrowForward } from "react-icons/io";

function SearchJobs() {
  const pdfUrl = 'path/to/your/pdf/file.pdf'; // Replace with the actual path to your PDF file

  return (
    <div className="container mt-4 searchJobs">
      <CSSTransition in={true} appear={true} timeout={1000} classNames="fade">
        <h1 className="display-4 mb-4">Climb Higher with Delta</h1>
      </CSSTransition>

      <CSSTransition in={true} appear={true} timeout={1000} classNames="fade" unmountOnExit>
        <p>
          <strong>CAREERS AT DELTA</strong>
          <br />
          There are 80,000+ reasons to join Delta. What’s yours?
        </p>
      </CSSTransition>

      <CSSTransition in={true} appear={true} timeout={1000} classNames="fade" unmountOnExit>
        <p>
          There are 80,000+ reasons to join Delta—every one of our employees has
          their own. Some of us want to explore new places. Some are here to
          explore our own career potential. Some are curious about other cultures,
          while others want to make a difference where they are. There’s a whole
          world out there—and another one right here within Delta. Which means
          that whatever keeps you climbing, you’ll discover it with us.
        </p>
      </CSSTransition>

      <Link to="/apply-jobs" className="btn btn-primary mt-3">
        Apply For Jobs
      </Link>

      <CSSTransition in={true} appear={true} timeout={1000} classNames="fade">
        <div className="mt-4">
          <div className="row">
            <div className="col-md-6">
              <Card>
                <Card.Img
                  variant="top"
                  src={employeesvolunteer} // Replace with your image source
                  alt="Delta Air Lines"
                />
              </Card>
            </div>

          


            <div className="col-md-6">
            <Link to="https://news.delta.com/category/people"  >
              <Card>
                <Card.Body>
                  <Card.Title>OUR CULTURE FUELS OUR SUCCESS.</Card.Title>
                  <Card.Text>
                    Delta Air Lines employs over 80,000 international employees.
                    Our culture is rooted in a shared commitment to living our
                    values – Honesty, Integrity, Respect, Perseverance, and
                    Servant Leadership – every day, in everything we do.
                  </Card.Text>
                </Card.Body>
                <div className='arrow'>
                    <IoIosArrowForward/>
                  </div>
              </Card>
            </Link>
            </div>
          </div>
        </div>
      </CSSTransition>

      <div>
        <div className='middleComponent'>
        <Link to="https://www.delta.com/us/en/about-delta/community-engagement/overview"  >
        <div className="col-md-6 insideDiv">
              <Card>
                <Card.Body>
                  <Card.Title>GIVING BACK HAS BEEN AT THE HEART OF DELTA FOR MORE THAN 75 YEARS.</Card.Title>
                  <Card.Text>
                   
                  Our people believe we have a fundamental mission 
                  to give back to the communities where we live, 
                  work and serve. Our employees contribute time, 
                  energy and millions of dollars to a host of 
                  worthwhile causes all over the world.
                                                      
                  </Card.Text>
                </Card.Body>
                <div className='arrow'>
                    <IoIosArrowForward/>
                  </div>
              </Card>
            </div>
            </Link>
        </div>
      </div> 



      <CSSTransition in={true} appear={true} timeout={1000} classNames="fade">
        <div className="mt-4">
          <div className="row">
            
            <div className="col-md-6 card-component">
              <div className='card'>
              <Link to="https://www.delta.com/us/en/about-delta/diversity"  >

              <Card>
                <Card.Body>
                  <Card.Title> BRINGING PEOPLE TOGETHER IS WHAT WE DO.
                  </Card.Title>
                  <Card.Text>
                  At Delta, we are committed to Diversity, Equity and Inclusion in the broadest 
                  sense to bring our values to life. We work to close representation gaps, strengthen our inclusive 
                  culture, cultivate a sense of belonging and advance equitable opportunities for
                  all employees to succeed.
                  </Card.Text>
                  
                </Card.Body>
                <div className='arrow'>
                    <IoIosArrowForward/>
                  </div>
              </Card>
              </Link>
              </div>
            </div>

           

            <div className="col-md-6">
              <Card>
                <Card.Img
                  variant="top"
                  src={atlantaprideparade} // Replace with your image source
                  alt="atlanta-pride-parade"
                />
              </Card>
            </div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={true} appear={true} timeout={1000} classNames="fade" unmountOnExit>
        <Benefits />
      </CSSTransition>

      <CSSTransition in={true} appear={true} timeout={1000} classNames="fade" unmountOnExit>
        <div className="row mt-3">
          <div>
            To view our full scope of benefits, please&nbsp;
            <Link to={pdfUrl} target="_blank" rel="noopener noreferrer">
              click here.
            </Link>
          </div>
          <div className='ex-quote'>
          “While many airlines fly the same aircraft, travel to popular destinations and use similar tools, it’s our people and the values-led culture they create that set Delta apart.”
          - Ed Bastian, Chief Executive Officer
          </div>
          <div>
            <Reviews />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={true} appear={true} timeout={1000} classNames="fade" unmountOnExit>
        <div className="equalOpportunity">
          <p>
            Delta Air Lines is an equal opportunity employer, including disability and veteran status.
          </p>
          <p>
            Delta Air Lines, Inc. is an Equal Employment Opportunity / Affirmative Action employer and provides reasonable accommodation in its application and selection process for qualified individuals, including accommodations related to compliance with conditional job offer requirements. Supporting medical or religious documentation will be required where applicable. To request a reasonable accommodation, please{' '}
            <Link to="/eeo-accommodations">click here</Link>.
          </p>
        </div>
      </CSSTransition>
    </div>
  );
}

export default SearchJobs;
