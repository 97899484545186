// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoMenu, IoClose } from 'react-icons/io5';
import './Navbar.css';
import Logo from '../icon/icon.svg';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className={`navbar navbar-expand-lg bg-body-tertiary ${isMenuOpen ? 'mobile-menu-open' : ''}`}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/" onClick={closeMenu}>
          {!isMenuOpen && <img src={Logo} alt="Logo" className="Nav-Logo" />}
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-expanded={isMenuOpen}
        >
          {isMenuOpen ? <IoClose className="menu-icon" /> : <IoMenu className="menu-icon" />}
        </button>

        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item home-icon-text">
              <div className='title'>
                <h1 className="title-text">
                  Delta
                </h1>
              </div>
            </li>
          </ul>

          <ul className={`navbar-nav ml-auto ${isMenuOpen ? 'mobile-menu' : ''}`}>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/apply-jobs" onClick={closeMenu}>
                Apply Jobs
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/talent-community" onClick={closeMenu}>
                Join Our Talent Community
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/eeo-accommodations" onClick={closeMenu}>
                EEO/Accommodations
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/deltapilot" onClick={closeMenu}>
                Delta Pilot
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/register" onClick={closeMenu}>
                Register
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/login" onClick={closeMenu}>
                Log In
              </Link>
            </li>
            {/* Add other navigation links here */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;




