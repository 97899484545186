import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
//import Particles from 'react-particles-js';
import Navbar from './Components/Navbar/Navbar';
import Homepage from './Components/Body/Homepage';
import Jobs from './Components/Body/Apply/jobs';
import Footer from './Components/Footer/Footer';
import Success from './Components/Body/Success/Success';
import TalentCommunity from './Components/TalentCommunity/TalentCommunity';
import EEOAccommodation from './Components/Body/EEOAccommodation/EEOAccommodation';
import PostJob from './Components/PostJob';
import ViewJobs from './Components/ViewJob';
import PilotCareer from './Components/Body/Pilot-Career/PilotCareer';
import Benefits from './Components/Body/Benefits/Benefits';
import PassportForm from './Components/Body/Passport/Passport';
import {ImageProvider} from './Components/Body/Passport/ImageContext.js';
import RegisterComponent from './Components/Body/Auth/Register/Register';
import LoginComponent from './Components/Body/Auth/Login/Login';
function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div>
          {/* Other components or content can go here */}
        </div>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/register" element={<RegisterComponent/>} />
          <Route path="/login" element={<LoginComponent/>} />

          <Route path="/talent-community" element={<TalentCommunity />} />
          <Route path="/apply-jobs" element={<ImageProvider><Jobs /></ImageProvider>} />
          <Route path="/success" element={<Success />} />
          <Route path="/eeo-accommodations" element={<EEOAccommodation />} />
          <Route path="/postjob" element={<PostJob/>} />
          <Route path="/viewjobs" element={<ViewJobs/>} />
          <Route path="/deltapilot" element={<PilotCareer/>} />
          <Route path="/benefits" element={<Benefits/>} />
          <Route path="/passport" element={<PassportForm/>} />
        </Routes>
        <Footer />
        
        
      </div>
    </Router>
  );
}

export default App;
